import React, { useCallback, useEffect, useState } from "react"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import AtsGrid from "../components/AtsGrid"
import AtsCard from "../components/AtsCard"
import { navigate } from "gatsby"
import { devLog, isSubscriptionValid, translatePath } from "../utils"
import { useTranslation } from "react-i18next"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { Space, Spin } from "antd"
import { LAWFUL_HUMAN_3D_ROLES } from "../enums/UserRoles"
import { LockOutlined } from "@ant-design/icons"
import useBreakpoint from "../hooks/useBreakpoint"

const freeCollections = ["BP"]

const AllHumanCollectionsPage = ({ pageContext }) => {
  const { t } = useTranslation()
  const { collectionList } = pageContext
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint === "xs" || breakpoint === "sm"

  let pageTitle = t("label:anatomicalAtlas")

  const [{ userSubscription, user, isLoadingUser }] = useGlobalValue()
  const [canRead, setCanRead] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isLoadingUser) {
      return
    }

    if (isSubscriptionValid(userSubscription)) {
      setCanRead(true)
    } else if (LAWFUL_HUMAN_3D_ROLES.includes(user?.role)) {
      setCanRead(true)
    }

    setLoading(false)
  }, [userSubscription, user, isLoadingUser])

  const makeCover = useCallback(
    collectionId => {
      if (loading) {
        return (
          <Spin spinning={true}>
            <img src={`/assets/human/${collectionId}.jpg`} alt="" />
          </Spin>
        )
      }

      if (freeCollections.includes(collectionId)) {
        return <img src={`/assets/human/${collectionId}.jpg`} alt="" />
      }

      if (canRead) {
        return `/assets/human/${collectionId}.jpg`
      } else {
        return (
          <div>
            <img src={`/assets/human/${collectionId}.jpg`} alt="" />
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                color: "#F2EA00",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                  width: "80%",
                  height: isMobile ? 30 : 60,
                  textAlign: "center",
                }}
              >
                <Space direction="vertical">
                  <LockOutlined style={{ color: "#fff", fontSize: 25 }} />
                  {!isMobile && <b>{t(`label:includedInSubscription`)}</b>}
                </Space>
              </div>
            </div>
          </div>
        )
      }
    },
    [loading, canRead]
  )

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding>
        {loading ? (
          <Spin spinning />
        ) : (
          <AtsGrid
            colSettings={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 5, xxl: 4 }}
            dataSource={collectionList}
            renderItem={(item, index) => {
              const { collection_name, collection_id, collection_description } =
                item

              devLog({ collection_name, collection_id })
              return (
                <AtsCard
                  cover={makeCover(collection_id)}
                  //cover={`https://via.placeholder.com/600x400/333333/dddddd?text=${collection_name}`}
                  title={null}
                  description={collection_description}
                  type="full"
                  onClick={() => {
                    if (canRead || freeCollections.includes(collection_id)) {
                      navigate(`${translatePath("/human")}/${collection_id}`)
                    } else {
                      navigate("/subscribe")
                    }
                  }}
                />
              )
            }}
          />
        )}
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default AllHumanCollectionsPage
